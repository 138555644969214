@keyframes gg-bar {
  10% {
    box-shadow: inset 0 -4px 0;
  }
  30% {
    box-shadow: inset 0 -10px 0;
  }
  60% {
    box-shadow: inset 0 -6px 0;
  }
  80% {
    box-shadow: inset 0 -8px 0;
  }
  to {
    box-shadow: inset 0 -2px 0;
  }
}
.gg-loadbar-sound,
.gg-loadbar-sound::after,
.gg-loadbar-sound::before {
  display: block;
  box-sizing: border-box;
  width: 2px;
  height: 12px;
  box-shadow: inset 0 -12px 0;
  animation: gg-bar 1.3s ease infinite alternate;
}
.gg-loadbar-sound {
  position: relative;
  transform: scale(var(--ggs, 1));
}
.gg-loadbar-sound::after,
.gg-loadbar-sound::before {
  content: '';
  position: absolute;
  bottom: 0;
}
.gg-loadbar-sound::before {
  left: -4px;
  animation-delay: -2.4s;
}
.gg-loadbar-sound::after {
  right: -4px;
  animation-delay: -3.7s;
}

.c_darkmode .theme_turquoise {
  --cc-bg: #161a1c;
  --cc-text: #d8e5ea;
  --cc-btn-primary-bg: #0f766e;
  --cc-btn-primary-text: #fff;
  --cc-btn-primary-hover-bg: #115e59;
  --cc-btn-secondary-bg: #242c31;
  --cc-btn-secondary-text: var(--cc-text);
  --cc-btn-secondary-hover-bg: #2b353c;
  --cc-toggle-bg-off: #667481;
  --cc-toggle-bg-on: var(--cc-btn-primary-bg);
  --cc-toggle-bg-readonly: #343e45;
  --cc-toggle-knob-bg: var(--cc-cookie-category-block-bg);
  --cc-toggle-knob-icon-color: var(--cc-bg);
  --cc-cookie-category-block-bg: #1e2428;
  --cc-cookie-category-block-bg-hover: #242c31;
  --cc-section-border: #222a30;
  --cc-block-text: #bac9cf;
  --cc-cookie-table-border: #2b3035;
  --cc-overlay-bg: rgba(4, 6, 8, 0.85);
  --cc-webkit-scrollbar-bg: #2c343a;
  --cc-webkit-scrollbar-bg-hover: #384148;
}

.c_darkmode .theme_turquoise .cc_div a {
  color: var(--cc-btn-primary-bg);
}

.c_darkmode .theme_turquoise #cc_div #s-hdr {
  border-bottom: none;
}

.c_darkmode .theme_turquoise .cc_div #c-txt {
  color: #b0b8c6 !important;
}
