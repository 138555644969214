#anime-search-input:focus {
  border-width: 0px;
  outline: 0;
  -webkit-appearance: none;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
}

.box {
  display: flex;
}
.box > * {
  flex: 1 1 200px;
}
.hide {
  visibility: hidden;
}
